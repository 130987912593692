import React from 'react'
import "../styles/components/MainSection.css";

function MainSection() {
  return (
    <main className="main-section">
    <div className="centered-content">
      <h1 className="title">sanchwho?</h1>
      <p className="subtitle">*(multidisciplinary designer)</p>
    </div>
    <div className="bottom-left">
      <p>sanchwho@gmail.com</p>
      <p>2024©</p>
    </div>
    <div className="bottom-right">
      <p>creating with intention and care</p>
    </div>
  </main>
  )
}

export default MainSection
import React from 'react';
import '../styles/components/ContactForm.css';

const ContactForm: React.FC = () => {
return (
    <div className="contact-form-container">
        <h1 className="contact-me-header">Get in touch</h1>
        <form className="contact-form">
            <div className="form-group">
                <label htmlFor="name">Name  </label>
                <input type="text" id="name" required />
            </div>

            <div className="form-group">
                <label htmlFor="email">Email  </label>
                <input type="email" id="email" required />
            </div>

            <div className="form-group message-input">
                <label htmlFor="message">Message</label>
                <input id="message" name="message" required></input>
            </div>

            <button type="submit">Contact Me</button>
        </form>
    </div>
);
};

export default ContactForm;
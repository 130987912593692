import React from 'react';
import '../styles/pages/Contact.css';
import ContactForm from '../components/ContactForm';

export default function Contact() {
  return (
    <div className="contact-page">
      <ContactForm />
    </div>
  );
}
import React from 'react';
import '../styles/pages/Architecture.css';

// Import all images from the assets folder
const importAll = (r: any) => r.keys().map(r);
const images = importAll(require.context('../assets/images/Architecture/', false, /\.(png|jpe?g|svg)$/));

// Function to extract the number from the filename
const getNumberFromFilename = (filename: string) => {
  const match = filename.match(/(\d+)/);
  return match ? parseInt(match[0], 10) : 0;
};

// Sort images based on the numbers in their filenames
const sortedImages = images.sort((a: any, b:any) => {
  const aNumber = getNumberFromFilename(a);
  const bNumber = getNumberFromFilename(b);
  return aNumber - bNumber;
});

export default function Architecture() {
  return (
    <div className="architecture-page">
      <div className="image-column">
        {sortedImages.map((image: string, index: number) => (
          <img key={index} src={image} alt={`Architecture ${index + 1}`} className="architecture-image" />
        ))}
      </div>
    </div>
  );
}
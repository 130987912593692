import React from 'react';
import '../styles/pages/About.css';

export default function About() {
  return (
    <div className="about-container">
      <p>
        The earth is held by love—a quiet, unbreakable bond that connects us to the spaces we inhabit and the
        stories they hold. As a designer, I move within this connection, exploring how design shapes the way we
        live, feel, and belong.      
      </p>
      <p>
        Design, to me, is a way of listening—to the rhythms of the world, the needs of the present, and the
        possibilities of the future. It is an act of care, where form, function, and meaning converge to create spaces
        that feel alive. Every design is an expression of understanding—honouring what is while imagining what
        could be.
      </p>
      <p>
        Each project begins with a single question: what does this place, this moment, ask of us? The answers lie
        in the land, the people, and the emotions they carry. The most powerful designs are those that connect—
        bridging people and places, ideas and emotions, the seen and the unseen.</p>
      <p>
        To design is to participate in something greater. It is the shaping of spaces that hold life, that invite stillness,
        reflection, and connection. It is creating work that echoes the pulse of the earth, reminding us of our place
        in its delicate balance.
      </p>
      <p>
        This is my practice: to design with intention, to honour the bond between humanity and the world, and to
        craft experiences that resonate deeply and endure.
      </p>
      <p>
        The earth is held by love, and through design, I strive to reflect this love—to create spaces that breathe,
        that connect, that inspire.
      </p>
    </div>
  );
}
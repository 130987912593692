import React from "react";
import { Link } from "react-router-dom";
import "../styles/components/Header.css";

function Header() {
  return (
    <header className="header">
      <nav style={{margin:'0em 0em 0em -2.4em'}}>
        <ul className="nav-links">
          <li><Link to="/about">about</Link></li>
          <li><Link to="/art">art</Link></li>
          <li><Link to="/architecture">architecture</Link></li>
        </ul>
      </nav>
      <div className="end-links">
          <Link to="/" className="contact-link">home</Link>
          <Link to="/contact" className="contact-link">contact</Link>
      </div>
    </header>
  );
}

export default Header;